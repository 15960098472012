import { FILE_BLACKLIST } from './file-black-list';

export async function getFilesFromFolders(dataTransferItems: DataTransferItemList, options = { addPathToName: true }) {

  let resultingFiles: File[] = [];
  const fileSystemEntries: FileSystemEntry[] = [];

  const checkError = (error: DOMException) => {
    if (error.name !== 'EncodingError') { return; }
    const errorMsg = `${error.name}\n`
                     + `Error message: "${error.message}"`;
    console.warn(errorMsg);
  };

  const filterBlacklistedFiles = (file: File): File => {
    return file && FILE_BLACKLIST.includes(file.name) ? undefined : file;
  };

  const renameFile = (originalFile: File, newName: string): File => {
    return new File(
      [originalFile],
      newName,
      {
        type: originalFile.type,
        lastModified: originalFile.lastModified
      }
    );
  };

  const readFile = (entry: FileSystemFileEntry, path = ''): Promise<File> => {
    return new Promise((resolve, reject) => {
      entry.file(file => {
        file = filterBlacklistedFiles(file);

        if (options.addPathToName && path && file) {
          file = renameFile(file, path + file.name);
        }
        resolve(file);
      }, (err) => {
        checkError(err);
        reject(err);
      });
    });
  };

  const readDirectoryEntries = (dirReader: FileSystemDirectoryReader, path: string): Promise<File[]> => {
    return new Promise((resolve, reject) => {
      dirReader.readEntries(async entries => {
        let files = [];
        for (const entry of entries) {
          const itemFiles = await getFilesFromEntry(entry, path);
          files = files.concat(itemFiles);
        }
        resolve(files);
      }, (err) => {
        checkError(err);
        reject(err);
      });
    });
  };

  const readDir = async (entry: FileSystemDirectoryEntry, path: string): Promise<File[]> => {
    const dirReader = entry.createReader();
    const newPath = path + entry.name + '/';
    let files = [];
    let newFiles: File[];
    do {
      newFiles = await readDirectoryEntries(dirReader, newPath);
      files = files.concat(newFiles);
    } while (newFiles.length > 0);
    return files;
  };

  const getFilesFromEntry = async (entry: FileSystemEntry, path = ''): Promise<File[]> => {
    if (entry.isFile) {
      const file = await readFile(entry as FileSystemFileEntry, path);
      return file ? [file] : [];
    }
    if (entry.isDirectory) {
      const files = await readDir(entry as FileSystemDirectoryEntry, path);
      return files;
    }
    throw new Error('Entry is neither file nor directory - unable to get files');
  };

  // Get all entries before reading them
  for (let i = 0, j = dataTransferItems.length; i < j; i++) {
    fileSystemEntries.push(dataTransferItems[i].webkitGetAsEntry());
  }

  // Recursively read through all entries
  for (const entry of fileSystemEntries) {
    const newFiles = await getFilesFromEntry(entry);
    resultingFiles = resultingFiles.concat(newFiles);
  }

  return resultingFiles;
}
