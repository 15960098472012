<div class="file-drop-area"
     [ngClass]="fileIsOver ? 'file-drop-area--file-over' : ''"
     (drop)="onDrop($event)"
     (dragover)="onDragOver($event)"
     (dragleave)="onDragLeave($event)"
>
  <div class="file-drop-area__icon">
    <fa-icon [icon]="['fas', 'arrow-from-bottom']"></fa-icon>
  </div>
  <div class="file-drop-area__hint-text mt-1">
    <h3 class="mb-0">{{ title }}</h3>
    <p class="mb-0">{{ instructions }}</p>
  </div>

  <input type="file"
         class="mt-2"
         multiple="true"
         (change)="onFilesSelected($event)"
  >
</div>
<ng-template ngFor let-fp [ngForOf]="fileProgress">
  <progressbar [animate]="true"
               [value]="fp.current_size"
               [max]="fp.max"
               [type]="fp.error ? 'danger' : fp.warning ? 'warning' : ( fp.current_size === fp.max ? 'success' : 'info' )"
               class="mb-1"
    ><b class="px-1">{{ fp.file_name }}<span *ngIf="fp.message" class="ms-1">{{ fp.message || '' }}</span></b>
  </progressbar>
</ng-template>
