import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { DirectUpload } from 'activestorage/src/direct_upload';
import { SessionService } from '@parashift/shared/services';
import { NotificationService } from '@parashift/shared/services';
import { getFilesFromFolders } from './get-files-from-folders';

export interface FileProgress {
  index: number;
  file_name: string;
  max: number;
  current_size: number;
  error?: boolean;
  warning?: boolean;
  message?: string;
}

export interface Blob {
  id: number;
  key: string;
  filename: string;
  content_type: string;
  metadata: object;
  byte_size: number;
  checksum: string;
  created_at: string;
  signed_id: string;
}

export interface UploadedFile {
  blob: Blob;
  fileName: string;
  index: number;
}

export type AfterUploadCallback = (file: UploadedFile) => void;
export type AfterMultipleUploadsCallback = (files: UploadedFile[]) => void;

const UPLOAD_LIMIT = 100;

@Component({
  selector: 'app-drop-and-upload-file',
  templateUrl: './drop-and-upload-file.component.html',
  standalone: true,
  imports: [NgClass, FaIconComponent, NgFor, ProgressbarModule, NgIf]
})
export class DropAndUploadFileComponent implements OnInit {

  @Input() title: string;
  @Input() instructions: string;
  @Input() allowedMimeTypes?: string[];

  @Output() readonly init = new EventEmitter<DropAndUploadFileComponent>();

  activeStorageHost: string;
  afterUploadCallback: AfterUploadCallback;
  afterMultipleUploadsCallback: AfterMultipleUploadsCallback;
  fileIsOver = false;
  fileProgress: FileProgress[] = [];
  files: UploadedFile[] = [];

  private activeStoragePath = '/v1/direct_uploads';
  private fileQueue = 0;

  constructor(
    private sessionService: SessionService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this.init.emit(this);
  }

  setActiveStorageHost(host: string) {
    this.activeStorageHost = host;
  }

  setAfterUploadCallback(afterUploadCallback: AfterUploadCallback) {
    this.afterUploadCallback = afterUploadCallback;
  }

  setAfterMultipleUploadsCallback(afterMultipleUploadsCallback: AfterMultipleUploadsCallback) {
    this.afterMultipleUploadsCallback = afterMultipleUploadsCallback;
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.fileIsOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
  }

  async onDrop(event: DragEvent) {
    event.preventDefault();
    this.fileProgress = [];
    const files = await getFilesFromFolders(event.dataTransfer.items);
    this.isWithinUploadLimit(files) && this.performUpload(files);
  }

  onFilesSelected(event: Event) {
    this.fileIsOver = true;
    event.preventDefault();
    this.fileProgress = [];

    const input = (event.target as HTMLInputElement);
    const files = Array.from(input.files as FileList);

    this.isWithinUploadLimit(files) && this.performUpload(files);
    input.value = '';
  }

  performUpload(files: Array<File>) {
    this.fileQueue = files.length;

    files.forEach((file: File, i: number) => {
      const index = i;
      this.fileProgress.push({
        index,
        file_name: file.name,
        max: file.size,
        current_size: 0,
        warning: this.unknownMimeType(file.type) ? true : false,
        message: this.unknownMimeType(file.type) ? $localize `:@@shared.components.drop_and_upload_file.mime_type.unknown:unknown MIME type` : undefined
      });

      if (this.checkMimeTypes(file.type)) {
        const upload = new DirectUpload(
          file, this.activeStorageHost + this.activeStoragePath, {
            directUploadWillCreateBlobWithXHR: (xhr: XMLHttpRequest) => {
              xhr.setRequestHeader('Authorization', `Bearer ${this.sessionService.session_token}`);
              xhr.setRequestHeader('Content-Type', file.type);
            },
            directUploadWillStoreFileWithXHR: (xhr: XMLHttpRequest) => xhr.upload.addEventListener('progress', event => this.setProgress(event, index))
          }
        );

        upload.create((error: string, blob: Blob) => {
          if (error) {
            this.notificationService.error({
              title: $localize `:@@shared.components.drop_and_upload_file.upload_error:Upload error`,
              description: $localize `:@@shared.components.drop_and_upload_file.upload_error_try_later:There was an error with uploading the file. Please contact customer support or try again later.`
            });

            this.setError(index, $localize `:@@shared.components.drop_and_upload_file.upload_error_try_later:There was an error with uploading the file. Please contact customer support or try again later.`);
            this.dispatchMultipleUploadCallback();

          } else if (this.afterUploadCallback) {
            this.afterUploadCallback({ blob, fileName: this.removeFileNameExtension(files[i].name), index: i });
          } else if (this.afterMultipleUploadsCallback) {
            this.files.push({ blob, fileName: this.removeFileNameExtension(files[i].name), index: i });
            this.dispatchMultipleUploadCallback();
          }
        });
      } else {
        this.setError(index, file.type + ' ' + $localize `:@@shared.components.drop_and_upload_file.mime_type.not_allowed:is not allowed`);
        this.dispatchMultipleUploadCallback();
      }
    });

    this.fileIsOver = false;
  }

  notifyDocumentCreationError(index: number, message: string) {
    this.setError(index, message);
  }

  private setError(index: number, message: string) {
    this.fileProgress[index].error = true;
    this.fileProgress[index].current_size = this.fileProgress[index].max;
    this.fileProgress[index].message = message;
  }

  private setProgress(event, index) {
    this.fileProgress[index].current_size = event.loaded;
  }

  private checkMimeTypes(fileType) {
    if (this.unknownMimeType(fileType)) {
      return true;
    }

    if (this.allowedMimeTypes && Array.isArray(this.allowedMimeTypes) && this.allowedMimeTypes.length > 0) {
      return this.allowedMimeTypes.find(type => fileType === type);
    }

    return true;
  }

  private unknownMimeType(fileType) {
    return !fileType;
  }

  private dispatchMultipleUploadCallback() {
    this.fileQueue -= 1;

    if (this.fileQueue === 0) {
      this.afterMultipleUploadsCallback(this.files);
    }
  }

  private isWithinUploadLimit(files: File[]): boolean {
    if (files.length > UPLOAD_LIMIT) {
      this.notificationService.warning({
        title: $localize `:@@shared.components.drop_and_upload_file.upload_limit.title:Limit reached`,
        description: $localize `:@@shared.components.drop_and_upload_file.upload_limit.description:You cannot upload more then 100 files at once. Please select fewer files.`
      });
      this.fileIsOver = false;
      return false;
    } else {
      return true;
    }
  }

  private removeFileNameExtension(fileName: string): string {
    const fileParts = (fileName || '').split('.');
    if (fileParts.length > 1) {
      fileParts.splice(fileParts.length - 1, 1);
    }
    return fileParts.join('');
  }
}
